<template>
  <Header />
  <Categories class="hlgd" />

  <NuxtPage />


  <UiScrollTopBtn />
  <Footer />
</template>

